<div style="margin-top: 2cm; margin-bottom: 1cm; margin-right: 25%; margin-left: 25%">
	<h1>Impressum</h1>
	<hr>
	Dillier MovingDev<br>
	Zellburg 2<br>
	6214 Schenkon<br>
	CH-Schweiz<br>
	<br>
	Vertreten durch: Colin Dillier<br>
	<!-- 
	<hr>
	<b>Registereintrag: </b><br>
	Eintragung im Handelsregister.<br> 
	Registergericht: Zürich<br>
	Registernummer: HRB 12345<br>
	-->
	<hr>
	<b>Kontakt:</b><br>
	&nbsp;&nbsp; Web: <a href="https://support.movingdev.com">support.movingdev.com</a><br>
	E-Mail: <a href="mailto:support@movingdev.com">support@movingdev.com</a><br>
	<hr>
	<b>VAT-Numbers / Tax-IDs (Global):</b><br>
	Switzerland: CHE-257.290.492<br><!-- https://www.uid.admin.ch/Detail.aspx?uid_id=CHE-257.290.492 -->
	Netherland: NL-257.290.492 <br>
	<hr>
	<b>Verantwortlich für den Inhalt:</b><br>
	Colin Dillier
	<hr>
	<br>
	Die Dillier MovingDev ("MovingDev“) ist ein Einzelunternehmen nach schweizerischem Recht mit eingetragenem Sitz in
	Schenkon.
	<!-- ist eine Geselschaft mit beschrenkter Haftung nach schweizerischem -->
</div>