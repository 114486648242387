<!-- Profile Übersicht -->
<div class="container">
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col col-lg-2 profilPicture">
					<img src="{{profilPicture}}" class="rounded-circle float-left" height="150" width="150">
				</div>
				<div class="col col-md-auto">
					<b>Nickname:</b> {{profilNickname}}<br> <b>E-Mail:</b> {{profilEMail}}<br>
					<b>Coins: </b>{{profilCoins}}<br><b>Roles: </b>{{profilRoles}}<br>
					<b>DiscordID: </b>{{discordID}}<br> <b>SpigotMC:</b>
					{{spigotUserID}}<br>
				</div>
			</div>
		</div>
	</div>
	<hr>
</div>

<!-- Link Services -->
<div class="container">
	<!-- Discord Alert Meldung -->
	<div class="alert alert-info" role="alert" *ngIf="!isDiscord()">
		You have a Discord Account? - Than link your profile, to synchronise your ranks.<br>
		<a href="https://discordapp.com/oauth2/authorize?client_id=623638003222577203&redirect_uri=https%3A%2F%2Fwww.movingdev.com%2Fprofil&response_type=token&scope=identify%20email"
			class="btn btn-primary">Link your Discord account</a>
	</div>
</div>

<!-- Gekaufte Produkte -->
<div class="container">
	<div class="card text-center">
		<div class="card-header">Your Plugins</div>
		<div class="card-columns">
			<div class="card p-2" style="width: 15rem; margin-bottom: 1rem; margin-top: 1rem; height: 15rem"
				*ngFor="let plugin of plugins">
				<img class="card-img-top" style="width: 5rem; height: 5rem;" src="{{plugin.ImageUrl}}">
				<div class="card-body">
					<h5 class="card-title">{{plugin.Name}}</h5>
					<p class="card-text">PluginID: {{plugin.ID}}<br>
						<b>Category:</b> {{plugin.Category}}
					</p>
				</div>
			</div>
			<div class="card p-2" style="width: 15rem; margin-bottom: 1rem; margin-top: 1rem; height: 15rem;"
				(click)="open(content)">
				<span style="font-size: 75px; color: Dodgerblue;">
					<i class="far fa-plus-circle"></i><br>
				</span>
				<p>Add bought ressource</p>
			</div>
		</div>
	</div>
</div>

<!-- Display on checking transaktion -->
<div class="container" *ngIf="isCheckingTransaction()">
	<hr>
	<div class="spinner-grow justify-content-center" role="status">
		<span class="sr-only">Loading...</span>
	</div>
	<div class="spinner-border justify-content-center" role="status">
		<span class="sr-only">Loading...</span>
	</div>
	<div class="spinner-grow justify-content-center" role="status">
		<span class="sr-only">Loading...</span>
	</div>
	<hr>
</div>

<!-- Modal for entering PayPal Transaction ID -->
<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Add a transaction</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form>
			<div class="form-group">
				<label for="paypalTransactionTitle">PayPal Transaction ID:</label>
				<div class="input-group">
					<input #paypalTransactionID id="paypalTransactionID" class="form-control" type="text">
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-primary"
			(click)="modal.close('Save|' + paypalTransactionID.value)">Save</button>
	</div>
</ng-template>