<div class="jumbotron">
  <h1 class="display-2 text-center">MovingDev Group</h1>
	<p class="lead text-center">{{langs.home.titlePrimary}}</p>
  <hr class="my-4">
  <p class="text-center">{{langs.home.titleSecondary}}</p>
</div>
<div class="container">
	<div class="card mb-3">
        <img class="card-img-top" src="./assets/image/plugins.jpg" width="1149" height="180" style="border-bottom-right-radius: 10px;border-bottom-left-radius: 10px" alt="Card image cap">
		<div class="card-body">
			<h5 class="card-title">{{langs.home.spigotTitle}}</h5>
			<p class="card-text">{{langs.home.spigotDescription}}</p>
			<a target="_blank" rel="noopener noreferrer" href="https://www.spigotmc.org/resources/authors/u.31663/" class="btn btn-outline-success"><i class="far fa-cauldron"></i> Visit SpigotMC</a>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<div class="card">
				<div class="card-body">
					<h5 class="card-title">{{langs.home.developerTitle}}</h5>
					<p class="card-text" style="height: 5rem;">{{langs.home.developerDescription}}</p>
					<a routerLink="/developer" class="btn btn-outline-success"><i class="far fa-code"></i> {{langs.home.developerTitle}}</a>
				</div>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="card">
				<div class="card-body">
					<h5 class="card-title">{{langs.home.minecraftTitle}}</h5>
					<p class="card-text" style="height: 5rem;">{{langs.home.minecraftDescription}}</p>
					<a target="_blank" rel="noopener noreferrer" href="http://linecraft.eu" class="btn btn-outline-success"><i class="far fa-axe"></i> Visit LineCraft</a>
				</div>
			</div>
		</div>
	</div>
	<br>
	<div class="card mb-3">
		<img class="card-img-top" src="./assets/image/programming.jpg" width="1149" height="180" style="border-bottom-right-radius: 10px;border-bottom-left-radius: 10px" alt="Card image cap">
		<div class="card-body">
			<h5 class="card-title">{{langs.home.projectsTitle}}</h5>
			<p class="card-text">{{langs.home.projectsDescription}}</p>
			<p class="card-text"><small class="text-muted">Comming Soon</small></p>
		</div>
	</div>
</div>