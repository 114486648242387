import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth0Service } from '../auth0.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html'
})
export class ProfilComponent {
  private static movingdevAPIToken = 'SthKag1mIcaeUB9STdiJN9C2DmVQdZr28rQrqo7O';
  checkingTransaction = false;
  profilPicture;
  profilEMail;
  profilNickname;
  profilCoins;
  profilRoles;
  plugins;
  discordID;
  spigotUserID;

  constructor(private route: ActivatedRoute, private http: HttpClient, private modalService: NgbModal, public auth: Auth0Service) {
    const JSONObject = ProfilComponent.getJsonObjectOfCurrentUser();
    this.profilPicture = JSONObject.picture;
    this.profilEMail = JSONObject.email;
    this.profilNickname = JSONObject.nickname;
    /*
    * Meta Info
    */
    const JSONMetaData = JSONObject['https://account.movingdev.com/metadata'];
    var userPlugin;
    if (JSONMetaData != null) {
      this.profilCoins = JSONMetaData.money;
      this.profilRoles = JSONMetaData.roles;
      if (JSONMetaData.minecraft != null) {
        this.spigotUserID = JSONMetaData.minecraft.spigotUserID;
        userPlugin = JSONMetaData.minecraft.mcplugins;
      }
      if (JSONMetaData.discord != null) {
        this.discordID = JSONMetaData.discord.id;
      }
    }
    /*
    * Check if data check was before
    */
    const discordIDFromStorage = localStorage.getItem('discordID')
    if (discordIDFromStorage != null) {
      this.discordID = discordIDFromStorage;
    }
    this.setParamesForOut();
    /*
    * Call APIs
    */
    if (this.plugins == null && userPlugin != null) {
      this.plugins = [];
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': ProfilComponent.movingdevAPIToken
        })
      };
      this.http.get('https://api.movingdev.com/webpage/getallplugins', httpOptions).subscribe(result => {
        const jsonReslut = result as JSON;
        for (var i = 0; i < Object.keys(jsonReslut).length; i++) {
          const plugin = jsonReslut[i];
          if (userPlugin.includes(plugin.ID)) {
            this.plugins.push(plugin);
          }
        }
      });
    }
  }

  isDiscord(): boolean {
    if (this.discordID != null) {
      return true;
    }
    return false;
  }

  isCheckingTransaction(): boolean {
    return this.checkingTransaction;
  }

  public static getJsonObjectOfCurrentUser() {
    return JSON.parse(localStorage.getItem('profil'));
  }

  setParamesForOut() {
    var discordToken = null;
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment != null) {
        discordToken = fragment.substring(fragment.indexOf('access_token') + 13, fragment.indexOf('&expires_in'));
      }
    })
    if (discordToken != null) {
      const httpDiscordOption = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + discordToken
        })
      }
      var discrodApiResult: JSON;
      this.http.get('https://discordapp.com/api/v6/users/@me', httpDiscordOption).subscribe(result => {
        discrodApiResult = result as JSON;
        // Set Ranks
        const httpOption = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': ProfilComponent.movingdevAPIToken,
            'discord_userid': discrodApiResult['id'],
            'discord_username': discrodApiResult['username'],
            'discord_email': discrodApiResult['email'],
            'movingdev_bearer': localStorage.getItem('openid').replace('"', '').replace('"', '')
          })
        };
        //CORS Headers AWS: 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,discord_userid,discord_username,discord_email,movingdev_bearer'
        this.http.post('https://api.movingdev.com/account/discord', {}, httpOption).subscribe(movingdevresult => {
          this.discordID = movingdevresult;
          console.log(this.discordID);
          localStorage.setItem('discordID', this.discordID);
        });
      });
    }

  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      var paypalTransactionID = result.split('|');
      if (paypalTransactionID[0] == 'Save') {
        this.checkingTransaction = true;
        console.log("PayPal Transaction: " + paypalTransactionID[1]);
        const httpOption = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': ProfilComponent.movingdevAPIToken,
            'paypal_transaktionID': paypalTransactionID[1],
            'movingdev_bearer': localStorage.getItem('openid').replace('"', '').replace('"', '')
          })
        }
        //CORS Headers AWS: 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,paypal_transaktionID,movingdev_bearer'
        this.http.post('https://api.movingdev.com/account/mctransaction', {}, httpOption).subscribe(result => {
          var discrodApiResult = result as JSON;
          if (JSON.stringify(discrodApiResult).includes("minecraft")) {
            console.log("Added MCPlugins");
            this.auth.logout();
          }
          console.log(discrodApiResult);
          this.checkingTransaction = false;
        });
      }
    });
  }
}