// Angular
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {AppComponent} from './app.component';
import {HeaderBarComponent} from './header-bar/headerbar.component';
import {FooterComponent} from './footer/footer.component';
import {HomepageComponent} from './homepage/homepage.component';
import { ProfilComponent } from './profil/profil.component';
import { ImprintComponent } from './imprint/imprint.component';
import { DisclaimerComponent } from './imprint/disclaimer/disclaimer.component';
import { PrivacyComponent } from './imprint/privacy/privacy.component';
import { ShopComponent } from './shop/shop.component';
import { DeveloperComponent } from './developer/developer.component';
import { GamingComponent } from './gaming/gaming.component';
import { CommingSoonComponent } from './commingSoon/commingSoon.component';
import { DonationComponent } from './donation/donation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const appRoutes: Routes = [
  { path: '', component: HomepageComponent},
  { path: 'home',  redirectTo: '', component: HomepageComponent},
  { path: 'developer', component: DeveloperComponent},
  { path: 'gaming', component: GamingComponent},
  { path: 'profil', component: ProfilComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'commingSoon', component: CommingSoonComponent},
  { path: 'donation', component: DonationComponent},
  { path: 'shop', component: ShopComponent },
  { path: '**', redirectTo: '', component: HomepageComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderBarComponent,
    FooterComponent,
    ProfilComponent,
    ImprintComponent,
    DisclaimerComponent,
    PrivacyComponent,
    DeveloperComponent,
    GamingComponent,
    CommingSoonComponent,
    ShopComponent,
    DonationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    RouterModule.forRoot(appRoutes),
    NgbModule,
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
