import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from '../language.service';
import { ProfilComponent } from '../profil/profil.component';
import { Auth0Service } from '../auth0.service';

declare function stripePayment(product: String, email: any): any;
declare var lock: any;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html'
})
export class ShopComponent {

  // Store plugins for session
  private static storePlugins: any;
  private url = 'https://api.movingdev.com/webpage/getallplugins';
  private apiKey = 'SthKag1mIcaeUB9STdiJN9C2DmVQdZr28rQrqo7O';
  plugins: any;
  isLoading = false;
  langs: any;

  constructor(private http: HttpClient, public auth: Auth0Service) {
    this.langs = LanguageService.getVariable();
    
    interface Plugin {
      ID: number;
      Name: string;
      Category: string;
      Price: number;
      PaymentID: string;
      ShortDescription: string;
      LongDescription: string;
      InformationUrl: string;
      DokumentationUrl: string;
      ImageUrl: string;
    }

    // API was already called
    this.plugins = ShopComponent.storePlugins;

    const test = localStorage.getItem('jwtAccessTocken');

    // Call API
    if (ShopComponent.storePlugins == null) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': this.apiKey
        })
      };

      this.isLoading = true;
      // tslint:disable-next-line:max-line-length
      this.http.get(this.url, httpOptions).subscribe(result => { ShopComponent.storePlugins = result as Plugin; this.isLoading = false; this.plugins = ShopComponent.storePlugins; });
    }
  }

  callPayment(checkoutItemID: String) {
    if (checkoutItemID != null) {
      //Get user ID, to add the purpurged product
      if (ProfilComponent.getJsonObjectOfCurrentUser() != null) {
        const userEMail = ProfilComponent.getJsonObjectOfCurrentUser().name;
        console.log("Buy product: " + checkoutItemID + " with E-Mail: " + userEMail);
        stripePayment(checkoutItemID, ProfilComponent.getJsonObjectOfCurrentUser().name);
      }
      else {
        console.log("for the purchase the user must be logged in!");
        this.auth.login();
      }
    }
  }
}
