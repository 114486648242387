import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-commingSoon',
  templateUrl: './commingSoon.component.html'
})
export class CommingSoonComponent implements OnInit {
  
  constructor() {
  }

  ngOnInit() {
  }

}