import { Component, OnInit } from '@angular/core';
import { ProfilComponent } from '../profil/profil.component';
declare function stripePayment(product, email): any;

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html'
})

export class DonationComponent implements OnInit {
  
  constructor() {
  }

  ngOnInit() {
  }

  callPayment(checkoutItemID: String) {
    if (checkoutItemID != null) {
        //Get user ID, to add the purpurged product
        let userEMail;
        let userTextGroup = (<HTMLInputElement>document.getElementById("donation-textgroup")).value;
        if (ProfilComponent.getJsonObjectOfCurrentUser() != null) {
            userEMail = ProfilComponent.getJsonObjectOfCurrentUser().email;
        }
            console.log("Buy product: " + checkoutItemID + " with E-Mail: " + userEMail + " sendText: '" + userTextGroup + "'");
        stripePayment(checkoutItemID, userEMail);
    }
  }

}