import { Component} from '@angular/core';
import {Auth0Service} from '../auth0.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-headerbar',
  templateUrl: './headerbar.component.html'
})
export class HeaderBarComponent {

  public langs: JSON;

  constructor(public auth: Auth0Service) {
    this.langs = LanguageService.getVariable();
  }
}
