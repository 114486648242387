import { HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';

export class LanguageService {
    // Store Langs for session
    private static storeLangs: JSON;

    constructor(private http: HttpClient, private loadingApp: AppComponent) {
        if (LanguageService.storeLangs == null) {
            var localeFile = '../assets/locale/locale.' + AppComponent.getUserLang() + '.json';
            this.http.get(localeFile).subscribe(data => {
                var jsonString = JSON.stringify(data);
                //console.info("LangService String: " + jsonString);
                LanguageService.storeLangs = JSON.parse(jsonString);
                loadingApp.isLoading = false;
            });
        }
    }

    static getVariable(): JSON {
        return LanguageService.storeLangs;
      }
}
