import { Component } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html'
})
export class DeveloperComponent {

  langs;
  private listCounter = 0;
  private disabledAgreement: boolean = true;
  private url = 'https://api.movingdev.com/webpage/pushdevrequest';

  constructor(private http: HttpClient,) {
    this.langs = LanguageService.getVariable();
    this.delay(400);
  }

  async delay(ms: number) {
    for (; this.listCounter <= 5; this.listCounter++) {
      await new Promise(resolve => setTimeout(() => resolve(), (ms + (20 * this.listCounter))));
    }
  }

  changeCheck(event){
    this.disabledAgreement = !this.disabledAgreement;
  }

  sendInSoftwareRequest(staticModal: any){
    // Call API
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': 'SthKag1mIcaeUB9STdiJN9C2DmVQdZr28rQrqo7O'
      })
    }
    //InputData
    const inputEmail = (<HTMLInputElement>document.getElementById("inputEmail")).value as String;
    const inputPrice = (<HTMLInputElement>document.getElementById("inputPrice")).value as String;
    const inputProjectDescription = (<HTMLInputElement>document.getElementById("inputProjectDescription")).value as String;
    const body = JSON.stringify({senderMail: inputEmail, price: inputPrice, description: inputProjectDescription});

    if (inputPrice.length > 1 && inputEmail.length > 4 && inputProjectDescription.length > 25){
      staticModal.show();
      this.http.post(this.url, body, httpOptions).subscribe(
        data => {
          document.getElementById('submitModal').classList.toggle('d-block');
          //window.location.href="";
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      );
      console.log("InputEmail:" + inputEmail + "\n Price:" + inputPrice + "\n Description:" +inputProjectDescription);
    }
    return false;
  }

  listCounterCheck(checkInt) {
    if (this.listCounter > checkInt) {
      return true;
    }
    return false;
  }
}