<div class="container mt-5" >
    <div class="shadow-lg p-3 mb-5 bg-white rounded text-center">
        <h1>COMING SOON</h1>
        <hr>
        <p>Thanks for visiting our website!<br> We are currently working on this section, so please be patient and came back later.</p>
        <br>
        <p>For now, you can visit our homepage: </p>
        <a routerLink="./home">https://www.movingdev.com</a>
        <hr>
        <div class="col-sm text-center">
            <i class="fas fa-cog fa-spin fa-5x"></i>
            <i class="fas fa-cog fa-spin fa-5x"></i>
            <i class="fas fa-cog fa-spin fa-5x"></i>
        </div>
    </div>
</div>