<hr>
<div class="container">
	<div class="row">
		<div class="col-sm">
			<ul class="list-group list-group-flush">
				<li class="list-group-item text-center font-weight-bold">
					<h3>{{langs.general.contactTitle}}</h3>
				</li>
				<li class="list-group-item">
					<div class="row">
						<div class="col-3 col-md-4 text-left">
							Web:
						</div>
						<div class="col text-left">
							<a target="_blank" rel="noopener noreferrer"
								href="http://support.movingdev.com">support.movingdev.com</a>
						</div>
					</div>
				</li>
				<li class="list-group-item">
					<div class="row">
						<div class="col-3 col-md-4 text-left">
							E-Mail:
						</div>
						<div class="col text-left">
							<a href="mailto:support@movingdev.com">support@movingdev.com</a>
						</div>
					</div>
				</li>
				<li class="list-group-item">
					<div class="row">
						<div class="col-3 col-md-4 text-left">
							Discord:
						</div>
						<div class="col text-left">
							<a target="_blank" rel="noopener noreferrer"
								href="http://discord.movingdev.com">discord.movingdev.com </a>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="col-sm">
			<ul class="list-group list-group-flush">
				<li class="list-group-item text-center font-weight-bold">
					<h3>{{langs.general.learnTitle}}</h3>
				</li>
				<li class="list-group-item text-center"><a target="_blank" rel="noopener noreferrer"
						href="http://support.movingdev.com">{{langs.general.helpASupportTitle}}</a></li>
				<li class="list-group-item text-center"><a target="_blank" rel="noopener noreferrer"
						href="http://wiki.movingdev.com">{{langs.general.documentationTitle}}</a></li>
				<li class="list-group-item text-center"><a target="_blank" rel="noopener noreferrer"
						href="http://explorer.movingdev.com">{{langs.general.apiExplorerTitle}}</a></li>
			</ul>
		</div>
		<div class="col-sm">
			<ul class="list-group list-group-flush">
				<li class="list-group-item text-center font-weight-bold">
					<h3>{{langs.general.languageTitle}}</h3>
				</li>
				<li class="list-group-item text-center">
					<button type="button" class="badge badge-success text-white px-3"
						*ngIf="checkUserLang('en')">English</button>
					<button type="button" class="badge badge-secondary text-white px-3" *ngIf="!checkUserLang('en')"
						(click)="updateUserLang('en')">English</button>
				</li>
				<li class="list-group-item text-center">
					<button type="button" class="badge badge-success text-white px-3"
						*ngIf="checkUserLang('de')">German</button>
					<button type="button" class="badge badge-secondary text-white px-3" *ngIf="!checkUserLang('de')"
						(click)="updateUserLang('de')">German</button>
				</li>
				<li class="list-group-item text-center"><a class="badge badge-info px-3 " href=""></a></li>
			</ul>
		</div>
	</div>
</div>
<hr>
<div class="container">
	<h3>
		<a class="float-xl-left px-4">&nbsp;</a>
		<a class="float-xl-left badge badge-danger text-white px-2" href="https://www.youtube.com/channel/UCm6q2Pfx9ZBcceVLFgnP3rg"><i class="fab fa-youtube fa-1x"></i></a>
		<a class="float-xl-left px-2">&nbsp;</a>
		<!-- TODO: Chage this if facebook is availibe -->
		<a class="float-xl-left badge badge-primary text-white px-3" href="https://twitter.com/movingdev"><i class="fab fa-facebook-f fa-1x"></i></a> 
		<a class="float-xl-left px-2">&nbsp;</a>
		<a class="float-xl-left badge badge-info text-white px-2" href="https://twitter.com/movingdev"><i class="fab fa-twitter fa-1x"></i></a>
	</h3>
</div>
<div class="container">
	<a class="float-xl-right text-black-50 badge badge-light" routerLink="/imprint">{{langs.general.imprintTitle}}</a>
	<a class="float-xl-right text-black-50 badge badge-light"
		routerLink="/privacy">{{langs.general.privacyPolicyTitle}}</a>
	<a class="float-xl-right text-black-50 badge badge-light"
		routerLink="/disclaimer">{{langs.general.disclaimerTitle}}</a>
</div>
<br>
<br>
<br>
<div class="mx-auto" style="width: 235px;">
	<a><i class="far fa-copyright"></i> 2018-{{date}} MovingDev Group</a>
</div>