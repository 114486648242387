<nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
	<a class="navbar-brand" routerLink="">MovingDEV</a>
	<!--  TODO: Doens't work for Handy -->
	<button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
		aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="collapse navbar-collapse" id="mainNavBar">
		<ul class="navbar-nav mr-auto">
			<li class="nav-item active">
				<a class="nav-link" routerLink=""><i class="far fa-home"></i> Home</a>
			</li>
			<li class="nav-item active dropdown" ngbDropdown>
				<button class="btn dropdown-toggle" id="dropdownMenuButton" type="button" data-toggle="dropdown"
					aria-pressed="false" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
					<i class="far fa-hand-holding-box"></i> Solutions</button>
				<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
					<button ngbDropdownItem onclick="location.href='https://www.spigotmc.org/resources/authors/u.31663/'">SpigotMC</button>
					<button ngbDropdownItem onclick="location.href='http://linecraft.eu'">LineCraft</button>
					<div class="dropdown-divider"></div>
					<button ngbDropdownItem routerLink="/developer">Development</button>
					<button ngbDropdownItem routerLink="/gaming">Gaming</button>
				</div>
			</li>
			<li class="nav-item active">
				<a class="nav-link" href="https://wiki.movingdev.com/doku.php">
					<i class="far fa-question-square"></i> Wiki
					<!-- TODO: Change this back to support page! -->
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" routerLink="shop"><i class="far fa-shopping-cart"></i> Shop</a>
			</li>
		</ul>
		<ul class="nav justify-content-end">
			<li class="nav-item">
				<button class="btn btn-secondary active" routerLink="/profil" *ngIf="auth.loggedIn"><i
						class="fal fa-user-circle fa-1x"></i></button>
			</li>
			<li class="nav-item">
				<a class="float-xl-left px-15">&nbsp;</a>
				<button class="btn btn-outline-success" (click)="auth.login()" *ngIf="!auth.loggedIn">
					<i class="fas fa-sign-in"></i> {{langs.general.signInTitle}}
				</button>
				<button class="btn btn-outline-danger" (click)="auth.logout()" *ngIf="auth.loggedIn">
					<i class="fas fa-sign-in"></i> Logout
				</button>
			</li>
		</ul>
	</div>
</nav>