import { AppComponent } from '../app.component';
import {Component, OnInit} from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  langs;
  date: number;

  constructor() {
    this.langs = LanguageService.getVariable();
    this.date = (new Date()).getFullYear();
  }

  ngOnInit() {
  }

  checkUserLang(lang: String): boolean {
      return AppComponent.checkUserLang(lang);
  }
  
  updateUserLang(lang: String){
     AppComponent.setUserLang(lang);
  }
}
