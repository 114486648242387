<div class="container mt-5" >
    <div class="shadow-lg p-3 mb-5 bg-white rounded text-center">
        <div class="row">
            <div class="col-sm-7 mx-auto">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Donation for plugin development</h5>
                        <hr>
                        <a class="btn btn-outline-success btn-lg mr-md-2" role="button" aria-pressed="true" (click)="callPayment('sku_FdjcL5wuUcjZWy')">5.00 EUR</a>
                        <a class="btn btn-outline-success btn-lg mr-md-2" role="button" aria-pressed="true" (click)="callPayment('sku_FdjcrXVSj2mqX0')">10.00 EUR</a>
                        <a class="btn btn-outline-success btn-lg mr-md-2" role="button" aria-pressed="true" (click)="callPayment('sku_Fz5FINu8lpOVeh')">25.00 EUR</a>
                        <a class="btn btn-outline-success btn-lg mr-md-2" role="button" aria-pressed="true" (click)="callPayment('sku_Fz5Gy9tXmGGQbP')">50.00 EUR</a>
                        <hr>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="donation-textgroup">Message</span>
                            </div>
                            <textarea class="form-control" aria-label="Message"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>