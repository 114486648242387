<div class="jumbotron">
	<h1 class="display-3 text-center">{{langs.shop.title}}</h1>
	<hr class="my-4">
	<p class="text-center">{{langs.shop.secondaryTitle}}</p>
</div>

<!--  Shop -->
<div class="container">
	<div class="progress" *ngIf="isLoading">
		<div *ngIf="isLoading" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
			aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Please wait a moment, ...</div>
	</div>
	<div class="card-columns">
		<div class="card p-2" style="width: 18rem; margin-bottom: 2rem; height: 30rem" *ngFor="let plugin of plugins">
			<img class="card-img-top" style="width: 16rem; height: 10rem" src="{{plugin.ImageUrl}}">
			<div class="card-body">
				<h5 class="card-title">{{plugin.Name}}</h5>
				<p class="card-text">{{plugin.ShortDescription}}</p>
			</div>
			<ul class="list-group list-group-flush">
				<li class="list-group-item"><b>Category:</b>
					{{plugin.Category}}</li>
				<li class="list-group-item" *ngIf="plugin.Price != 0"><b>Price:</b>
					{{plugin.Price}} &euro;</li>
				<li class="list-group-item" *ngIf="plugin.Price == 0"><b>Price:</b>
					<div color="green"> Free of charge!</div>
				</li>
			</ul>
			<div class="card-body mb-3">
				<a class="btn btn-outline-primary btn-lg float-sm-left" role="button" aria-pressed="true"
					href="{{plugin.InformationUrl}}">Info</a>
				<button class="btn btn-outline-success btn-lg float-sm-right" role="button" aria-pressed="true"
					(click)="callPayment(plugin.PaymentID)">Buy</button>
			</div>
		</div>
	</div>
</div>

<!-- Shop Info -->
<div class=" container">
	<div class="alert alert-light" role="alert">
		<small>{{langs.shop.vatAnnotation}}</small>
	</div>
</div>