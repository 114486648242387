import { Component } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html'
})
export class HomepageComponent {

  langs: any;

  constructor() {
    this.langs = LanguageService.getVariable();
  }
}
