<div class="container">
    <img class="card-img-top" src="./assets/image/programming.jpg" width="1149" height="180"
        style="border-bottom-right-radius: 10px;border-bottom-left-radius: 10px" alt="Card image cap">
    <div class="container">
        <div class="shadow-sm p-3 mb-5 bg-white rounded text-center">
            <h1 class="display-4">{{langs.home.developerTitle}}</h1>
            {{langs.developer.descriptionFirst}} <br>
            {{langs.developer.descriptionSecond}}
            <hr>
            {{langs.developer.descriptionThird}}
        </div>
    </div>
</div>
<div class="container">
    <div class="card mb-3">
        <ul class="list-unstyled">
            <li *ngIf="listCounterCheck(0)" class="media">
                <div class="mr-5 mt-3 ml-5">
                    <i class="far fa-question-circle fa-5x"></i>
                </div>
                <div class="media-body">
                    <h5 class="mt-2 mb-1">{{langs.developer.steps.one.title}}</h5>
                    {{langs.developer.steps.one.description}}
                </div>
            </li>
            <hr *ngIf="listCounterCheck(1)">
            <li *ngIf="listCounterCheck(1)" class="media">
                <div class="media-body">
                    <h5 class="mt-0 mb-1 ml-5">{{langs.developer.steps.two.title}}</h5>
                    <div class="ml-5">
                        {{langs.developer.steps.two.description}}
                    </div>
                </div>
                <div class="ml-5 mt-3 mr-5">
                    <i class="fab fa-searchengin fa-5x"></i>
                </div>
            </li>
            <hr *ngIf="listCounterCheck(2)">
            <li *ngIf="listCounterCheck(2)" class="media">
                <div class="mr-5 mt-3 ml-5">
                    <i class="far fa-hammer fa-5x"></i>
                </div>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">{{langs.developer.steps.three.title}}</h5>
                    {{langs.developer.steps.three.description}}
                </div>
            </li>
            <hr *ngIf="listCounterCheck(3)">
            <li *ngIf="listCounterCheck(3)" class="media">
                <div class="media-body">
                    <h5 class="mt-0 mb-1 ml-5">{{langs.developer.steps.fourth.title}}</h5>
                    <div class="ml-5">
                        {{langs.developer.steps.fourth.description}}
                    </div>
                </div>
                <div class="ml-5 mt-3 mr-5">
                    <i class="far fa-stethoscope fa-5x"></i>
                </div>
            </li>
            <hr *ngIf="listCounterCheck(4)">
            <li *ngIf="listCounterCheck(4)" class="media">
                <div class="mr-5 mt-3 ml-5">
                    <i class="far fa-money-bill-alt fa-5x"></i>
                </div>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">{{langs.developer.steps.fifth.title}}</h5>
                    {{langs.developer.steps.fifth.description}}
                </div>
            </li>
        </ul>
        <hr>
        <h3 *ngIf="listCounterCheck(5)" class="ml-5">{{langs.developer.contactDetails}}</h3>
        <div *ngIf="listCounterCheck(5)" class="row mb-3">
            <div class="col-sm ">
                <form class="ml-5">
                    <div class="form-group">
                        <label for="inputEmail">Email address</label>
                        <input type="email" class="form-control" id="inputEmail" placeholder="Enter email" required>
                    </div>
                    <div class="form-group">
                        <label for="enterPrice">How much you want to pay?</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">€</span>
                            </div>
                            <input type="number" min="15" step="1" class="form-control" placeholder="min. 15,00 €"
                                aria-label="Amount (to the nearest dollar)" id="inputPrice" required>
                            <div class="input-group-append">
                                <span class="input-group-text">,00</span>
                            </div>
                        </div>
                        <small id="priceHelp"
                            class="form-text text-muted">{{langs.developer.pricingInformation}}</small>
                    </div>
                    <div class="form-group">
                        <label for="inputProjectDescription">Describe your project:</label>
                        <textarea type="text" class="form-control" id="inputProjectDescription" rows="10" required></textarea>
                    </div>
                    <div class="form-group form-check">
                        <input (change)="changeCheck($event)" type="checkbox" class="form-check-input" id="policyTitleCheck" required>
                        <label class="form-check-label" for="policyTitleCheck">
                            Accept the {{langs.general.privacyPolicyTitle}}
                        </label>
                    </div><!-- (click)="sendInSoftwareRequest(staticModal)" -->
                    <button [disabled]="disabledAgreement" type="submit" class="btn btn-outline-success" >Submit</button>
                </form>
            </div>
            <div class="col-sm">
                {{langs.developer.expertsTechnologies}}
                <ul>
                    <li>Java Standard Edition (SE)</li>
                    <li>Java Enterprise Edition (EE)</li>
                    <li>C# </li>
                    <li>Structured Query Language (SQL / MySQL)</li>
                    <li>Extended Structured Query Language (ESQL) </li>
                    <li>Java Script & Angular (JS)</li>
                    <li>Hypertext Preprocessor (PHP) </li>
                    <li>Python </li>
                    <li>... </li>
                </ul>
                {{langs.developer.expertsProtokolls}}
                <ul>
                    <li>REST</li>
                    <li>SOAP</li>
                    <li>Websockets </li>
                    <li>MQTT </li>
                    <li>AMQP</li>
                    <li>MQ </li>
                    <li>... </li>
                </ul>
                <div class="text-center mr-3 ml-2">
                    <hr>
                    {{langs.developer.generalInfo}}
                    <br>
                    {{langs.developer.pricingInformation}}
                </div>
            </div>
        </div>
    </div>
</div>


<!-- MODAL Profile Picture 
<div class="modal fade" bsModal #staticModal="bs-modal"
	[config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
	aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Your request has been submitted.</h5>
			</div>
			<div class="modal-body">
				Your sales team rates your projekt. <br>
				If we are excited and we think we could programm your solution within your budget. You get a replay within 48 hours!
				<hr>
				<button class="btn btn-success" routerLink="/">Thank you!</button>
			</div>
		</div>
	</div>
</div>
-->
