import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LanguageService } from './language.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'MovingDev';
  isLoading: boolean = true;
  // tslint:disable-next-line:member-ordering
  private static userLang;
  constructor(private router: Router, private http: HttpClient) {}

  ngOnInit() {
    /*
    * Loads Language Settings
    */
    this.setUserLangInit();
    new LanguageService(this.http, this);

    // This events, jums to the top of page after routing
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  /*
   * User lang methodes
   */
  private setUserLangInit() {
    var userLangInit = localStorage.getItem('userLang');
    if (userLangInit == null) {
      userLangInit = navigator.language;
      console.log("NAV_LANG: " + userLangInit);
      if (userLangInit.length > 2) {
        userLangInit = userLangInit.substr(0, 2);
      }
      var listLangs = ['de', 'en'];
      if (!(listLangs.includes(userLangInit))){
        userLangInit = "en";
      }
    }
    AppComponent.userLang = userLangInit;
  }

  static setUserLang(lang) {
    if (this.userLang != lang) {
      localStorage.setItem('userLang', lang);
      //Reload for lang Update
      window.location.reload();
    }
  }

  static checkUserLang(lang): boolean {
    if (this.userLang === lang) {
      return true;
    }
  }

  static getUserLang(): String{
      return this.userLang;
  }
}