<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <h1 class="display-4">Gaming</h1>
        <p class="lead">Comming Soon!</p>
        <div class="row">
            <div class="col-sm text-center">
                <i class="fas fa-cog fa-spin fa-5x"></i>
                <i class="fas fa-cog fa-spin fa-5x"></i>
                <i class="fas fa-snowman fa-5x"></i>
                <i class="fas fa-cog fa-spin fa-5x"></i>
                <i class="fas fa-cog fa-spin fa-5x"></i>
            </div>
        </div>
    </div>
</div>